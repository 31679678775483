var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{attrs:{"app":"","color":"white","height":"100"}},[_c('v-avatar',{staticClass:"mr-3",attrs:{"color":"grey lighten-5","size":"70"}},[_c('v-img',{attrs:{"contain":"","max-height":"70%","src":require("./assets/iconfinder_Mask_1_5947115.png")}})],1),_c('v-toolbar-title',{staticClass:"font-weight-black headline"},[_vm._v(" neo725.xyz ")])],1),_c('v-main',[_c('section',{staticClass:"header-section",attrs:{"id":"hero"}},[_c('v-row',{attrs:{"no-gutters":""}},[(false)?_c('v-img',{attrs:{"min-height":'calc(100vh - ' + _vm.$vuetify.application.top + 'px)',"src":"https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1951&q=80"}}):_vm._e(),_c('v-img',{attrs:{"gradient":"to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)","src":require("./assets/2012-04-10 19.54.25.jpg")}}),_c('v-theme-provider',{attrs:{"dark":""}},[_c('v-container',{attrs:{"fill-height":""}},[_c('v-row',{staticClass:"white--text mx-auto",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"white--text text-center",attrs:{"cols":"12","tag":"h1"}},[_c('span',{staticClass:"font-weight-light",class:[
                    _vm.$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2'
                  ]},[_vm._v(" WELCOME TO ")]),_c('br'),_c('span',{staticClass:"font-weight-black",class:[
                    _vm.$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4'
                  ]},[_vm._v(" neo725.xyz ")])]),_c('v-btn',{staticClass:"align-self-end",attrs:{"fab":"","outlined":""},on:{"click":function($event){return _vm.$vuetify.goTo('#about-me')}}},[_c('v-icon',[_vm._v("mdi-chevron-double-down")])],1)],1)],1)],1)],1)],1),_c('section',{attrs:{"id":"about-me"}},[_c('div',{staticClass:"py-12"}),_c('v-container',{staticClass:"text-center"},[_c('h2',{staticClass:"display-2 font-weight-bold mb-3"},[_vm._v("ABOUT ME")]),_c('v-responsive',{staticClass:"mx-auto mb-8",attrs:{"width":"56"}},[_c('v-divider',{staticClass:"mb-1"}),_c('v-divider')],1),_c('v-responsive',{staticClass:"mx-auto title font-weight-light mb-8",attrs:{"max-width":"720"}},[_vm._v(" # ")]),_c('v-avatar',{staticClass:"elevation-12 mb-12",attrs:{"size":"128"}},[_c('v-img',{attrs:{"src":require("./assets/neo-180.png")}})],1),_c('div'),(false)?_c('v-btn',{attrs:{"color":"grey","href":"https://vuetifyjs.com","outlined":"","large":""}},[_c('span',{staticClass:"grey--text text--darken-1 font-weight-bold"},[_vm._v(" Vuetify Documentation ")])]):_vm._e()],1),_c('div',{staticClass:"py-12"})],1),(false)?_c('section',{staticClass:"grey lighten-3",attrs:{"id":"features"}},[_c('div',{staticClass:"py-12"}),_c('v-container',{staticClass:"text-center"},[_c('h2',{staticClass:"display-2 font-weight-bold mb-3"},[_vm._v("VUETIFY FEATURES")]),_c('v-responsive',{staticClass:"mx-auto mb-12",attrs:{"width":"56"}},[_c('v-divider',{staticClass:"mb-1"}),_c('v-divider')],1),_c('v-row',_vm._l((_vm.features),function(ref,i){
                  var icon = ref.icon;
                  var title = ref.title;
                  var text = ref.text;
return _c('v-col',{key:i,attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"py-12 px-4",attrs:{"color":"grey lighten-5","flat":""}},[_c('v-theme-provider',{attrs:{"dark":""}},[_c('div',[_c('v-avatar',{attrs:{"color":"primary","size":"88"}},[_c('v-icon',{attrs:{"large":""},domProps:{"textContent":_vm._s(icon)}})],1)],1)]),_c('v-card-title',{staticClass:"justify-center font-weight-black text-uppercase",domProps:{"textContent":_vm._s(title)}}),_c('v-card-text',{staticClass:"subtitle-1",domProps:{"textContent":_vm._s(text)}})],1)],1)}),1)],1),_c('div',{staticClass:"py-12"})],1):_vm._e(),(false)?_c('section',{attrs:{"id":"stats"}},[_c('v-parallax',{attrs:{"height":_vm.$vuetify.breakpoint.smAndDown ? 700 : 500,"src":"https://images.unsplash.com/photo-1510915228340-29c85a43dcfe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80"}},[_c('v-container',{attrs:{"fill-height":""}},[_c('v-row',{staticClass:"mx-auto"},_vm._l((_vm.stats),function(ref){
                  var value = ref[0];
                  var title = ref[1];
return _c('v-col',{key:title,attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"display-3 font-weight-black mb-4",domProps:{"textContent":_vm._s(value)}}),_c('div',{staticClass:"title font-weight-regular text-uppercase",domProps:{"textContent":_vm._s(title)}})])])}),1)],1)],1)],1):_vm._e(),(false)?_c('section',{attrs:{"id":"blog"}},[_c('div',{staticClass:"py-12"}),_c('v-container',[_c('h2',{staticClass:"display-2 font-weight-bold mb-3 text-uppercase text-center"},[_vm._v(" Blog ")]),_c('v-responsive',{staticClass:"mx-auto mb-12",attrs:{"width":"56"}},[_c('v-divider',{staticClass:"mb-1"}),_c('v-divider')],1),_c('v-row',_vm._l((_vm.articles),function(ref,i){
                  var src = ref.src;
                  var text = ref.text;
                  var title = ref.title;
return _c('v-col',{key:i,attrs:{"cols":"12","md":"4"}},[_c('v-img',{staticClass:"mb-4",attrs:{"src":src,"height":"275","max-width":"100%"}}),_c('h3',{staticClass:"font-weight-black mb-4 text-uppercase",domProps:{"textContent":_vm._s(title)}}),_c('div',{staticClass:"title font-weight-light mb-5",domProps:{"textContent":_vm._s(text)}}),_c('v-btn',{staticClass:"ml-n4 font-weight-black",attrs:{"text":""}},[_vm._v(" Continue Reading ")])],1)}),1)],1),_c('div',{staticClass:"py-12"})],1):_vm._e(),_c('v-sheet',{attrs:{"id":"contact","color":"#333333","dark":"","tag":"section","tile":""}},[_c('div',{staticClass:"py-12"}),_c('v-container',[_c('h2',{staticClass:"display-2 font-weight-bold mb-3 text-uppercase text-center"},[_vm._v(" Contact Me ")]),_c('v-responsive',{staticClass:"mx-auto mb-12",attrs:{"width":"56"}},[_c('v-divider',{staticClass:"mb-1"}),_c('v-divider')],1),_c('v-theme-provider',{attrs:{"light":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"flat":"","label":"Name*","solo":""}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"flat":"","label":"Email*","solo":""}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"flat":"","label":"Subject*","solo":""}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"flat":"","label":"Message*","solo":""}})],1),_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"accent","x-large":""}},[_vm._v(" Submit ")])],1)],1)],1)],1),_c('div',{staticClass:"py-12"})],1)],1),_c('v-footer',{staticClass:"justify-center",attrs:{"color":"#292929","height":"100"}},[_c('div',{staticClass:"title font-weight-light grey--text text--lighten-1 text-center"},[_vm._v(" © "+_vm._s(new Date().getFullYear())+" — Vuetify, Made with Neo"),_c('br'),_vm._v("LLC — Theme with 💜 by John Leider ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }